interface IProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  c1?: boolean;
  c2?: boolean;
  c3?: boolean;
  c4?: boolean;
  c5?: boolean;
  bold?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export default function Text({
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  c1,
  c2,
  c3,
  c4,
  c5,
  bold,
  className = "text-slate-800",
  children,
}: IProps) {
  const heading = h1
    ? "text-h1"
    : h2
      ? "text-h2"
      : h3
        ? "text-h3"
        : h4
          ? "text-h4"
          : h5
            ? "text-h5"
            : h6
              ? "text-h6"
              : "";
  const component = c1
    ? "text-c1"
    : c2
      ? "text-c2"
      : c3
        ? "text-c3"
        : c4
          ? "text-c4"
          : c5
            ? "text-c5"
            : "";

  const fontWeightClass = bold
    ? component
      ? "font-medium"
      : "font-semibold"
    : component
      ? "font-normal"
      : "font-medium";

  if (heading)
    return (
      <h1 className={`${fontWeightClass} ${heading} ${className}`}>
        {children}
      </h1>
    );
  if (component)
    return (
      <p className={`${fontWeightClass} ${component} ${className}`}>
        {children}
      </p>
    );
  return <p>{children}</p>;
}
