import { TooltipRenderProps } from 'react-joyride';
import CoachmarkIcon from '@/assets/images/coachmark-icon.svg';
import Text from '@/components/atoms/Text';
import { twMerge } from 'tailwind-merge';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { t } from 'i18next';

export default function CoachmarkItem(props: TooltipRenderProps) {
  return (
    <div
      className="tooltip__body flex flex-col gap-3 rounded-xl bg-white p-4"
      {...props.tooltipProps}
    >
      <div className="flex items-start gap-4">
        <img src={CoachmarkIcon} />

        <div className="space-y-3">
          <Text
            c4
            className="tooltip__content max-w-[248px]"
          >
            {props.step.content}
          </Text>

          <div className="tooltip__footer flex items-center justify-between">
            <div className="flex gap-2">
              {Array.from({ length: props.size }).map((_, indexStep) => (
                <div
                  className={twMerge(
                    'h-2 w-2 rounded-full',
                    indexStep === props.index ? 'bg-[#2B5FAC]' : 'bg-gray-30'
                  )}
                />
              ))}
            </div>

            <div className="tooltip__spacer tooltip__footer flex gap-2">
              {props.index > 0 && (
                <button
                  className="tooltip__button flex h-6 w-6 items-center justify-center rounded-md border border-[#2B5FAC] text-center"
                  {...props.backProps}
                >
                  <ChevronLeftIcon
                    className="text-[#2B5FAC]"
                    width={16}
                    height={16}
                  />
                </button>
              )}

              {!props.isLastStep ? (
                <button
                  className="tooltip__button tooltip__button--primary h-6 items-center rounded-lg bg-[#2B5FAC] px-[10px]"
                  {...props.primaryProps}
                >
                  <Text
                    c4
                    bold
                    className="text-white"
                  >
                    {twMerge('words.next')}
                  </Text>
                </button>
              ) : (
                <button
                  className="tooltip__button tooltip__button--primary h-6 items-center rounded-lg bg-[#2B5FAC] px-[10px]"
                  {...props.primaryProps}
                >
                  <Text
                    c4
                    bold
                    className="text-white"
                  >
                    {twMerge('words.done')}
                  </Text>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
