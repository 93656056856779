import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IRequestGetSyncDocument,
  TReturnGetSyncDocument,
  IRequestPostSyncDocument,
  IRequestGetSyncDocumentStatus,
  TReturnGetSyncDocumentStatus,
} from './type';

export const documentApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'documentApi',
  tagTypes: ['GET_SYNC_DOCUMENT', 'GET_SYNC_DOCUMENT_STATUS'],
  endpoints: builder => ({
    /**
     * MyCrew: Get List Sync Document
     */
    getSyncDocument: builder.query<TReturnGetSyncDocument, IRequestGetSyncDocument>({
      query: ({ crewCompanyAssignmentId }) =>
        `/v1/company/company-assignment/${crewCompanyAssignmentId}/document/sync`,
      providesTags: ['GET_SYNC_DOCUMENT'],
    }),

    /**
     * My Crew: Post Sync Document
     */
    postSyncDocument: builder.mutation<void, IRequestPostSyncDocument>({
      query: data => ({
        url: `/v1/company/company-assignment/${data.crewCompanyAssignmentId}/document/sync`,
        method: 'POST',
        body: data.payload,
      }),
      invalidatesTags: ['GET_SYNC_DOCUMENT'],
    }),

    /**
     * My Crew: Get Sync Document Status
     */
    getSyncDocumentStatus: builder.query<
      TReturnGetSyncDocumentStatus,
      IRequestGetSyncDocumentStatus
    >({
      query: ({ crewCompanyAssignmentId }) =>
        `/v1/company/company-assignment/${crewCompanyAssignmentId}/document/sync/status`,
      providesTags: ['GET_SYNC_DOCUMENT_STATUS'],
    }),
  }),
});

export const {
  useGetSyncDocumentQuery,
  usePostSyncDocumentMutation,
  useLazyGetSyncDocumentStatusQuery,
} = documentApi;
