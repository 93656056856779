import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import { PAGINATION_LIMIT } from '@/constants/pagination';

import type {
  TRequestEditAdmin,
  TResponseAdminForManage,
  IResponseGetAdminItemForManageList,
  IReturnGetAdminItemForManageList,
  IRequestParamGetAdminForManageList,
  IReturnAdminItem,
  IResponseAdminList,
  TResponsePicItemList,
} from './type';
import type { TUserStatus } from '@/interfaces/user';
import { IReturnListApi } from '@/interfaces/common/list';
import { rtkInfiniteScrollConfig } from '@/config/api/infiniteScroll';
import { IDataDropdown } from '@/interfaces/dropdown';

export interface IRequestDisableAdmin {
  email: string;
  status: TUserStatus;
}

export const adminApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'adminApi',
  tagTypes: ['ADMIN', 'PIC_LIST'],
  endpoints: builder => ({
    getAdminList: builder.query<IReturnListApi<IReturnAdminItem>, { page: number }>({
      query: payload => ({
        url: '/v1/company/admin',
        params: {
          page: payload.page,
          limit: PAGINATION_LIMIT,
        },
      }),
      transformResponse: (response: IResponseAdminList) => {
        return {
          pagination: response?.meta,
          list:
            response.items?.map(el => ({
              label: el.name || '',
              value: el.id || '',
            })) || [],
        };
      },
      merge: rtkInfiniteScrollConfig.merge,
      serializeQueryArgs: rtkInfiniteScrollConfig.serializeQueryArgs,
      forceRefetch: rtkInfiniteScrollConfig.forceRefetch,
      providesTags: ['ADMIN'],
    }),
    getAdminListForManage: builder.query<
      IReturnGetAdminItemForManageList,
      IRequestParamGetAdminForManageList
    >({
      query: ({ page, search, role, status }) => ({
        url: '/v1/company/admin',
        params: {
          name: search,
          roleId: role,
          status,
          page,
          limit: PAGINATION_LIMIT,
        },
      }),
      providesTags: ['ADMIN'],
      transformResponse: (response: IResponseGetAdminItemForManageList) => {
        const transformedData = response.items?.map(({ position, ...data }) => {
          return { ...data, jobTitle: position };
        });
        return {
          list: transformedData as TResponseAdminForManage[],
          pagination: response.meta,
        };
      },
    }),
    deleteAdmin: builder.mutation({
      query: (idAdmin: string) => ({
        url: `/v1/company-user/${idAdmin}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ADMIN'],
    }),
    editAdmin: builder.mutation<{ id: string }, TRequestEditAdmin>({
      query: payload => ({
        url: '/v1/company-user',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['ADMIN'],
    }),
    disableAdmin: builder.mutation({
      query: (payload: IRequestDisableAdmin) => ({
        url: '/v1/company-user/disable',
        body: payload,
        method: 'PATCH',
      }),
      invalidatesTags: ['ADMIN'],
    }),
    getPicList: builder.query<IDataDropdown[], void>({
      providesTags: ['PIC_LIST'],
      query: () => ({
        url: '/v1/company/pic-user',
        method: 'GET',
      }),
      transformResponse: (response: TResponsePicItemList) => {
        return response?.data.map(el => ({
          value: el.id,
          label: el.name || '',
        }));
      },
    }),
  }),
});

export const {
  useGetAdminListQuery,
  useEditAdminMutation,
  useLazyGetAdminListQuery,
  useGetAdminListForManageQuery,
  useDeleteAdminMutation,
  useDisableAdminMutation,
  useGetPicListQuery,
} = adminApi;
