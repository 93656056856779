import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';

import type {
  IRequestGetConversationInfo,
  IResponseGetConversationInfo,
  IResponseFreezeConversation,
  IRequestFreezeConversation,
  IResponseStartConversationWithContext,
  IRequestStartConversationWithContext,
  IResponseStartConversationWithoutContext,
  IRequestStartConversationWithoutContext,
} from './type';

export const conversationApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'conversationApi',
  tagTypes: ['CONVERSATION_INFO'],
  endpoints: builder => ({
    getConversationInfo: builder.query<IResponseGetConversationInfo, IRequestGetConversationInfo>({
      query: payload => ({
        url: `/v1/company/conversation/${payload.conversationId}`,
      }),
      providesTags: ['CONVERSATION_INFO'],
    }),
    freezeConversation: builder.mutation<IResponseFreezeConversation, IRequestFreezeConversation>({
      query: payload => ({
        method: 'PATCH',
        url: `/v1/company/crew/${payload.crewId}/conversation/freeze`,
      }),
      invalidatesTags: ['CONVERSATION_INFO'],
    }),
    startConversationWithContext: builder.mutation<
      IResponseStartConversationWithContext,
      IRequestStartConversationWithContext
    >({
      query: payload => ({
        method: 'POST',
        url: `/v1/company/crew/${payload.crewId}/conversation/send-job-info`,
        body: {
          jobId: payload.jobId,
        },
      }),
    }),
    startConversationWithoutContext: builder.mutation<
      IResponseStartConversationWithoutContext,
      IRequestStartConversationWithoutContext
    >({
      query: payload => ({
        method: 'POST',
        url: `/v1/company/crew/${payload.crewId}/conversation/start`,
      }),
    }),
  }),
});

export const {
  useLazyGetConversationInfoQuery,
  useFreezeConversationMutation,
  useStartConversationWithContextMutation,
  useStartConversationWithoutContextMutation,
} = conversationApi;
