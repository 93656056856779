import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IRequestPostCoachmark } from './type';

export const coachmarkApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'coachmarkApi',
  tagTypes: ['COACHMARK'],
  endpoints: builder => ({
    getCoachmark: builder.query<Array<string>, void>({
      query: () => '/v1/company/coachmark',
      providesTags: ['COACHMARK'],
    }),
    postCoachmark: builder.mutation<void, IRequestPostCoachmark>({
      query: body => ({
        url: '/v1/company/coachmark',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['COACHMARK'],
    }),
  }),
});

export const { useGetCoachmarkQuery, usePostCoachmarkMutation } = coachmarkApi;
