import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IResponseGetCompanyInfo,
  IResponseGetCompanyDetail,
  IRequestEditCompanyInfo,
  IRequestEditCompanyDetail,
  IReturnColorTag,
  IColorTagItem,
} from './type';
import { IResponseListApi } from '@/interfaces/common/list';

export const companyApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'companyApi',
  tagTypes: ['COMPANY_INFO', 'COMPANY_DETAIL', 'COMPANY_COLOR_TAG'],
  endpoints: builder => ({
    getCompanyInfo: builder.query<IResponseGetCompanyInfo, void>({
      query: () => '/v1/company/info',
      providesTags: ['COMPANY_INFO'],
    }),
    getCompanyDetail: builder.query<IResponseGetCompanyDetail, void>({
      query: () => '/v1/company/detail',
      providesTags: ['COMPANY_DETAIL'],
    }),
    editCompanyInfo: builder.mutation<IResponseGetCompanyInfo, IRequestEditCompanyInfo>({
      query: body => ({
        url: '/v1/company/info',
        method: 'PATCH',
        invalidatesTags: ['company-info'],
        body,
      }),
    }),
    editCompanyDetail: builder.mutation<IResponseGetCompanyInfo, IRequestEditCompanyDetail>({
      query: body => ({
        url: '/v1/company/detail',
        method: 'PATCH',
        invalidatesTags: ['company-detail'],
        body,
      }),
    }),
    getColorTag: builder.query<IReturnColorTag, void>({
      query: () => '/v1/company/color',
      transformResponse: (data: IResponseListApi<IColorTagItem>) => {
        return (
          data.items?.map(item => ({
            value: item.id,
            label: item.remark,
            hex: item.colorGroup.value,
          })) || []
        );
      },
      providesTags: ['COMPANY_COLOR_TAG'],
    }),
  }),
});

export const {
  useGetCompanyInfoQuery,
  useGetCompanyDetailQuery,
  useEditCompanyInfoMutation,
  useEditCompanyDetailMutation,
  useGetColorTagQuery,
} = companyApi;
