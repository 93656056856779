import { notificationApi } from '@/api/notification';
import React, { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useDispatch } from 'react-redux';

interface IProps {
  children: React.ReactNode;
}

async function runOneSignal() {
  await OneSignal.init({
    appId: import.meta.env.VITE_ONE_SIGNAL_ID,
    allowLocalhostAsSecureOrigin: true,
    persistNotification: true,
    autoRegister: true,
    autoResubscribe: true,
    notifyButton: {
      enable: true,
    },
    notificationClickHandlerMatch: 'origin',
    notificationClickHandlerAction: 'focus',
  });
  OneSignal.Slidedown.promptPush();
}

export default function NotificationProvider({ children }: IProps) {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  OneSignal.Notifications.addEventListener('foregroundWillDisplay', e => {
    dispatch(notificationApi.util.invalidateTags(['NOTIFICATION_COUNTER']));
  });

  // this on click listener not functional if user not in the same url as baseUrl parsed by BE
  // TODO: handle this click listener later
  OneSignal.Notifications.addEventListener('click', e => {});

  useEffect(() => {
    if (!initialized) {
      runOneSignal()
        .then(() => {
          setInitialized(true);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [initialized]);

  return <>{children}</>;
}
