import { IRoleAccessItem } from '@/interfaces/admin';

export type TResponseRoles = {
  id: string;
  value: string;
  label: string;
  accesses: IAccess[];
}[];

export interface IAccess {
  id: string;
  value: string;
  label: string;
  Section: (typeof roleSection)[keyof typeof roleSection];
  description: string;
}

export const roleSection: { [key: string]: TRoleSection } = {
  JOB: 'job',
  RATE: 'rate',
  SETTING: 'setting',
  ALL: 'all',
  VESSEL: 'vessel',
  MY_CREW: 'myCrew',
  CREW: 'crew',
};

export type TRoleSection = 'job' | 'setting' | 'rate' | 'all' | 'vessel' | 'myCrew' | 'crew';

export interface IRequestAddRole {
  id?: string;
  role: string;
  accesses: string[];
}

export type IRequestEditRole = IRequestAddRole;

export type TReturnRoleAccessList = IRoleAccessItem[];
