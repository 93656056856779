import { IUserData } from '@/interfaces/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IInitialStateAuthSlice {
  token: string | null;
  hasAuthenticated: boolean | null;
  redirectTo: string;
  user: IUserData;
}

const initialState: IInitialStateAuthSlice = {
  token: '',
  hasAuthenticated: null,
  redirectTo: '',
  user: {
    id: '',
    name: '',
    email: '',
    role: '',
    jobTitle: '',
    gender: null,
    avatar: '',
    phoneNumber: '',
    status: null,
    accesses: [],
    isShowOnBoarding: false,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserData: (state, action: PayloadAction<IUserData>) => {
      state.user = action.payload;
    },
    setHasAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.hasAuthenticated = action.payload;
    },
    setRedirectTo: (state, action: PayloadAction<string>) => {
      state.redirectTo = action.payload;
    },
    logout: () => initialState,
  },
});

export const { setToken, setUserData, setHasAuthenticated, setRedirectTo, logout } =
  authSlice.actions;

export default authSlice.reducer;
