import { t } from 'i18next';
import toast from 'react-hot-toast';

const errorByStatusCode = {
  '500': t('common:error:badGateway'),
  '501': t('common:error:badGateway'),
  '502': t('common:error:badGateway'),
  '503': t('common:error:badGateway'),
  '504': t('common:error:badGateway'),
  '509': t('common:error:badGateway'),
  '511': t('common:error:badGateway'),
  '413': t('common:error:payloadTooLarge'),
};

interface IErrorApiMantis {
  error: string; // Message
  errorCode: string; // ex: ME_001_1
  errorDetail: string; // ex: "unauthorized"
  errorStatus: number; // ex: 401
}

export interface IErrorRtkQuery {
  status: number | string;
  data: IErrorApiMantis;
}

export interface ICustomErrorMessage {
  [status: string]: string;
}

export const errorHandler = (error: IErrorRtkQuery | any, customError?: ICustomErrorMessage) => {
  if (error.status === 'FETCH_ERROR') {
    toast.error(t('common:error:somethingWrongWhenFetchingData'));
    return;
  }

  if (customError) {
    const errorMessageByCode = customError?.[error?.data?.errorStatus];
    if (errorMessageByCode) {
      toast.error(errorMessageByCode);
      return;
    }
  }

  const errorMessageByStatus =
    errorByStatusCode?.[String(error?.status) as keyof typeof errorByStatusCode];
  if (errorMessageByStatus) {
    toast.error(errorMessageByStatus);
    return;
  }

  toast.error(error?.data?.error || error?.error || error?.message);
};
