import { createApi } from '@reduxjs/toolkit/query/react';
import { PAGINATION_LIMIT } from '@/constants/pagination';
import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';

import {
  TRequestDeleteColor,
  TRequestGetColorPagination,
  TRequestPatchEditColor,
  TRequestPostAddColor,
  TResponseGetColorPagination,
  TReturnGetColorPagination,
  TReturnGetCompanyColorList,
} from './type';

export const colorApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'colorApi',
  tagTypes: ['COLOR', 'COLOR_LIST'],
  endpoints: builder => ({
    getColorPagination: builder.query<TReturnGetColorPagination, TRequestGetColorPagination>({
      query: payload => ({
        url: `/v1/company/color`,
        method: 'GET',
        params: {
          page: payload.page,
          limit: PAGINATION_LIMIT,
        },
      }),
      transformResponse: (response: TResponseGetColorPagination) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
      providesTags: ['COLOR'],
    }),
    getCompanyColorList: builder.query<TReturnGetCompanyColorList, void>({
      query: payload => ({
        url: `/v1/company/color-list`,
        method: 'GET',
      }),
      providesTags: ['COLOR_LIST'],
    }),
    postAddColor: builder.mutation<void, TRequestPostAddColor>({
      query: payload => ({
        url: `/v1/company/color`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['COLOR', 'COLOR_LIST'],
    }),
    patchEditColor: builder.mutation<void, TRequestPatchEditColor>({
      query: payload => ({
        url: `/v1/company/color/${payload.companyColorId}`,
        method: 'PATCH',
        body: {
          colorGroupId: payload.colorGroupId,
          remark: payload.remark,
        },
      }),
      invalidatesTags: ['COLOR', 'COLOR_LIST'],
    }),
    deleteColor: builder.mutation<void, TRequestDeleteColor>({
      query: payload => ({
        url: `/v1/company/color/${payload.companyColorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['COLOR', 'COLOR_LIST'],
    }),
  }),
});

export const {
  useGetColorPaginationQuery,
  useGetCompanyColorListQuery,
  usePostAddColorMutation,
  usePatchEditColorMutation,
  useDeleteColorMutation,
} = colorApi;
