import { lowercaseExceptCapslock } from '@/helpers/format/text';
import i18n from 'i18next';
import I18NextChainedBackend from 'i18next-chained-backend';
import I18NextLocalStorageBackend from 'i18next-localstorage-backend';
import I18NextLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import id from './id.json';

const resources = {
  en,
  id,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18NextChainedBackend)
  .use(I18NextLanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    backend: {
      backends: [I18NextLocalStorageBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
      ],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
      // eslint-disable-next-line no-unused-vars
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase-all') return value.toLowerCase();
        if (format === 'lowercase') return lowercaseExceptCapslock(value);
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    },
  });

export default i18n;
