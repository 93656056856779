import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';

import { IRequestUploadFile, IReturnUploadFile, IResponseUploadFile } from './type';

export const fileApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'fileApi',
  endpoints: builder => ({
    uploadFile: builder.mutation<IReturnUploadFile, IRequestUploadFile>({
      query: payload => ({
        url: '/v1/company/upload',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: IResponseUploadFile) => {
        return {
          fileId: response.fileID, // biar konsisten pake camelCase
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = fileApi;
