import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';

import type { IDashboardStatisticData } from '@/components/organisms/dashboard/Statistic';
import type { TDashboardChartData } from '@/components/organisms/dashboard/Graph';

export const dashboardApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'dashboardApi',
  tagTypes: ['statistic'],
  endpoints: builder => ({
    getStatistic: builder.query<IDashboardStatisticData, Date | null>({
      query: date => ({
        url: '/v1/company/statistic/applicant',
        params: {
          'start-date': date ? format(new Date(date), 'yyyy-MM-dd') : undefined,
        },
      }),
    }),
    getGraph: builder.query<TDashboardChartData, 'WEEK' | 'MONTH' | 'YEAR'>({
      query: type => ({
        url: '/v1/company/statistic/visitor',
        params: {
          interval: type,
        },
      }),
      transformResponse: (response: { data: TDashboardChartData }) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetStatisticQuery, useGetGraphQuery } = dashboardApi;
