import { createApi } from '@reduxjs/toolkit/query/react';
import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { IDownloadAllResponse } from '@/interfaces/downloadAll/type';
import {
  IRequestGetDownloadAllMyCrewCertificate,
  IRequestGetRequestDownloadAllMyCrewCertificate,
  IReturnGetRequestDownloadAllMyCrewCertificate,
} from './type';

export const downloadCertificatesApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'downloadCertificatesApi',
  endpoints: builder => ({
    getRequestDownloadAllCertificates: builder.query<{ message: string }, { crewId: string }>({
      query: ({ crewId }) => ({
        url: `/v1/company/request-archive/certificates/${crewId}`,
        method: 'GET',
      }),
    }),
    getDownloadAllCertificates: builder.query<IDownloadAllResponse, { crewId: string }>({
      query: ({ crewId }) => ({
        url: `/v1/company/archive/certificates/${crewId}`,
        method: 'GET',
      }),
    }),

    /**
     * MyCrew: Request Download All My Crew Certificate
     */
    getRequestDownloadAllMyCrewCertificate: builder.query<
      IReturnGetRequestDownloadAllMyCrewCertificate,
      IRequestGetRequestDownloadAllMyCrewCertificate
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/request-archive/certificate`,
        method: 'GET',
      }),
    }),

    /**
     * MyCrew: Download All My Crew Certificate
     */
    getDownloadAllMyCrewCertificates: builder.query<
      IDownloadAllResponse,
      IRequestGetDownloadAllMyCrewCertificate
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/archive/certificate`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetDownloadAllCertificatesQuery,
  useLazyGetRequestDownloadAllCertificatesQuery,
  useLazyGetDownloadAllMyCrewCertificatesQuery,
  useLazyGetRequestDownloadAllMyCrewCertificateQuery,
} = downloadCertificatesApi;
