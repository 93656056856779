import { useGetCoachmarkQuery, usePostCoachmarkMutation } from '@/api/coachmark';
import { IRequestPostCoachmark } from '@/api/coachmark/type';
import { errorHandler } from '@/helpers/error';

export default function useFetchCoachmark() {
  const { data: dataCoachmark, isLoading: isLoadingCoachmark } = useGetCoachmarkQuery();
  const [postCoachmark] = usePostCoachmarkMutation();

  const handlePostCoachmark = async (payload: IRequestPostCoachmark) => {
    try {
      await postCoachmark(payload).unwrap();
    } catch (error) {
      errorHandler(error);
    }
  };

  return { dataCoachmark, isLoadingCoachmark, handlePostCoachmark };
}
