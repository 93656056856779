import { mantisPublicApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IRequestLoginCompanyUser, IResponseLoginCompanyUser } from './type';

export const authLoginApi = createApi({
  ...mantisPublicApiConfig,
  reducerPath: 'authLoginApi',
  endpoints: builder => ({
    loginCompanyUser: builder.mutation<string, IRequestLoginCompanyUser>({
      query: body => ({
        url: '/v1/login/company-user',
        method: 'POST',
        body,
      }),
      transformResponse: (response: IResponseLoginCompanyUser) => response.token,
    }),
  }),
});

export const { useLoginCompanyUserMutation } = authLoginApi;
