import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { IRequestPostRate, IResponseGetCompanyCrewPrefilledData } from './type';

export const rateApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'rateApi',
  tagTypes: ['rate'],
  endpoints: builder => ({
    submitRate: builder.mutation<void, IRequestPostRate>({
      query: payload => ({
        url: '/v1/company-crew/rate',
        body: payload,
        method: 'POST',
      }),
    }),
    getCompanyCrewPrefilledData: builder.query<
      IResponseGetCompanyCrewPrefilledData,
      { id: string }
    >({
      query: payload => ({
        url: `/v1/company-crew/${payload.id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useSubmitRateMutation, useGetCompanyCrewPrefilledDataQuery } = rateApi;
