import React, { useCallback, useEffect, useState } from 'react';
import Talk from 'talkjs';
import { Session } from '@talkjs/react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { TALK_JS_APP_ID } from '@/config/talkjs';

interface IMessageProviderProps {
  children: React.ReactNode;
}

export default function MessageProvider({ ...props }: IMessageProviderProps) {
  const userData = useSelector((state: RootState) => state.auth.user);

  const [companyUser, setCompanyUser] = useState<Talk.User | null>(null);

  const handleSetActiveUser = useCallback(async () => {
    await Talk.ready;
    setCompanyUser(
      new Talk.User({
        id: userData.id,
        name: userData?.name || '',
        email: userData?.email || '',
        photoUrl: userData?.avatar || null,
        role: 'company',
      })
    );
  }, [userData]);

  useEffect(() => {
    // Initialize TalkJS when the component mounts
    if (userData.id) {
      handleSetActiveUser();
    }
  }, [userData]);

  if (!TALK_JS_APP_ID || !companyUser) {
    // Display a loading indicator or placeholder content
    // while TalkJS is loading or user data is not available
    return <>{props.children}</>;
  }

  return (
    <Session
      appId={TALK_JS_APP_ID}
      syncUser={companyUser}
    >
      {props.children}
    </Session>
  );
}
