import { Step } from 'react-joyride';

export const STEPS_DATA: Array<{
  location: string;
  steps: Array<Step>;
  enum: 'MARKS_DASHBOARD' | 'MARKS_JOBS';
}> = [
  {
    location: '/dashboard',
    enum: 'MARKS_DASHBOARD',
    steps: [
      {
        target: '.MARKS_DASHBOARD_STATISTIC_HEADER',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
        placement: 'left',
      },
      {
        target: '.MARKS_DASHBOARD_STATISTIC_CARD_APPLIED',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_DASHBOARD_STATISTIC_CARD_INVITE_INTERVIEW',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_DASHBOARD_STATISTIC_CARD_FINAL_OFFER',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_DASHBOARD_STATISTIC_CARD_FINAL_OFFER_ACCEPTED',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_DASHBOARD_GRAPH_HEADER',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_DASHBOARD_CHART_LINE',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
    ],
  },
  {
    location: '/jobs',
    enum: 'MARKS_JOBS',
    steps: [
      {
        target: '.MARKS_JOBS_HEADER_BUTTON',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
        placement: 'left',
      },
      {
        target: '.MARKS_JOBS_FILTER_SELECT',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_JOBS_FILTER_SEARCH',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
        placement: 'left',
      },
      {
        target: '.MARKS_JOBS_TABLE',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
      {
        target: '.MARKS_JOBS_TABLE_PAGINATION',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        disableBeacon: true,
      },
    ],
  },
];
