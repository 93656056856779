import { TCrewApplicantStatus } from '@/interfaces/crew/detail';
import { TRate } from '@/interfaces/crew/rating';
import { TFilterOfferStatus } from '@/interfaces/jobs/application';
import { t } from 'i18next';

/**
 * @description this function allows converting object of payload to query params
 * to be used in RTK Query API
 * @param param is an object of payload
 * @returns string of combined object to query params
 */
export const convertObjectToQueryParams = (param: object): URLSearchParams => {
  const params = new URLSearchParams();

  const appendParam = (key: string, value: string) => {
    params.append(key, String(value));
  };

  Object.entries(param).forEach(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        value.forEach(data => {
          appendParam(key, data);
        });
      } else {
        appendParam(key, value);
      }
    }
  });

  return params;
};

/**
 * @description this function allows converting applicant status value from response to string
 * @param param is an string of crew status. ex: EX_CREW
 * @returns string of formatted crew status. ex: Ex-Crew
 */
export const convertApplicantStatusValueToLabel = (status: TCrewApplicantStatus): string => {
  switch (status) {
    case 'EX_CREW':
      return t('common:label:applicantStatus:exCrew');
    case 'NO_RATE':
      return t('common:label:applicantStatus:noRating');
    case 'EVER_APPLIED':
      return t('common:label:applicantStatus:everApplied');
    default:
      return '';
  }
};

/**
 * @description this function allows converting rate key value to string
 * @param param is an string of crew status. ex: EXCELLENT;GOOD;FAIR;NO_RATE
 * @returns string of formatted crew status. ex: No-Rate, Excellent, Good
 */
export const convertRateStatusValueToLabel = (rateStatus: TRate): string => {
  switch (rateStatus) {
    case 'EXCELLENT':
      return t('common:label:rateStatus:excellent');
    case 'NO_RATE':
      return t('common:label:rateStatus:noRating');
    case 'FAIR':
      return t('common:label:rateStatus:fair');
    case 'GOOD':
      return t('common:label:rateStatus:good');
    default:
      return '';
  }
};

/**
 * @description this function allows converting offer status value from response to string
 * @param param is an string of crew status. ex: IN_REVIEW
 * @returns string of formatted crew status. ex: In Review
 */
export const convertApplicantOfferStatusValueToLabel = (
  offerStatus: TFilterOfferStatus
): string => {
  switch (offerStatus) {
    case 'OFFER_REJECTED':
      return t('common:label:applicationStatus:offerRejected');
    case 'FINAL_OFFER_ACCEPTED':
      return t('common:label:applicationStatus:finalOfferAccepted');
    case 'FINAL_OFFER_ACCEPTED_FROM_OTHER_JOB':
      return t('common:label:applicationStatus:acceptedOtherJobs');
    case 'REJECTED':
    case 'CANCEL_SHORTLISTED':
    case 'FINAL_OFFER_REJECTED':
    case 'REVOKE_FINAL_OFFER':
    case 'REVOKE_SALARY_CHECK':
    case 'CANCEL_INTERVIEW':
    case 'CANCEL_FINAL_OFFER':
    case 'SHORTLIST_REJECTED':
      return t('common:label:applicationStatus:rejected');
    case 'APPLIED':
      return t('common:label:applicationStatus:applied');
    case 'IN_REVIEW':
      return t('common:label:applicationStatus:inReview');
    case 'SHORTLIST_NEED_COMPLETION':
      return t('common:label:applicationStatus:shortlistedNeedCompletion');
    case 'SHORTLISTED':
      return t('common:label:applicationStatus:shortlisted');
    case 'INVITE_INTERVIEW':
      return t('common:label:applicationStatus:inviteInterview');
    case 'CLOSED_JOB':
      return t('common:label:applicationStatus:closedJob');
    case 'SALARY_CHECK':
      return t('common:label:applicationStatus:notYetConfirmed');
    case 'OFFER_ACCEPTED':
      return t('common:label:applicationStatus:offerAccepted');
    case 'FINAL_OFFER':
      return t('common:label:applicationStatus:finalOffer');
    default:
      return '';
  }
};
