import ReactDOM from 'react-dom/client';
import './index.css';
import './plugins/sentry';
import './plugins/logrocket';
import './plugins/i18n';
import './plugins/mixpanel';
import Router from './router';

import ToasterPlugin from './plugins/toaster';
import ReduxProvider from './providers/redux';
import LayoutError from './pages/layout/Error';
import NotificationProvider from './providers/notification';
import MessageProvider from './providers/message';
import ProvidersCoachmark from './providers/coachmark';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ReduxProvider>
    <NotificationProvider>
      <ToasterPlugin />
      <LayoutError>
        <MessageProvider>
          <ProvidersCoachmark>
            <Router />
          </ProvidersCoachmark>
        </MessageProvider>
      </LayoutError>
    </NotificationProvider>
  </ReduxProvider>
);
