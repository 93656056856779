import { RootState } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getInitialState = (): { sideBar: boolean } => ({
  sideBar: false,
});

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: getInitialState(),
  reducers: {
    toggleSidebar: state => {
      state.sideBar = !state.sideBar;
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.sideBar = action.payload;
    },
  },
});

export const getSidebar = (state: RootState) => state.sidebar.sideBar;

export const { toggleSidebar, setSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
