import Card from '@/components/atoms/Card';
import ContainerMain from '@/components/atoms/container/Main';
import { useEffect, useState } from 'react';

export default function LayoutErrorNoInternet({ children }: { children: React.ReactNode }) {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
  });
  window.addEventListener('offline', () => {
    setOnline(false);
  });

  if (isOnline) return <>{children}</>;

  return (
    <ContainerMain>
      <Card
        extraRounded
        extraShadow
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold text-gray-800">No Internet</h1>
            <p className="text-gray-600">Please check your internet connection</p>
          </div>
        </div>
      </Card>
    </ContainerMain>
  );
}
