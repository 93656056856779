import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IRequestGetSyncCertificate,
  TReturnGetSyncCertificate,
  IRequestPostSyncCertificate,
  TReturnGetSyncCertificateStatus,
  IRequestGetSyncCertificateStatus,
} from './type';

export const certificateApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'certificateApi',
  tagTypes: ['GET_SYNC_CERTIFICATE', 'GET_SYNC_CERTIFICATE_STATUS'],
  endpoints: builder => ({
    /**
     * MyCrew: Get List Sync Certificate
     */
    getSyncCertificate: builder.query<TReturnGetSyncCertificate, IRequestGetSyncCertificate>({
      query: ({ crewCompanyAssignmentId }) =>
        `/v1/company/company-assignment/${crewCompanyAssignmentId}/certificate/sync`,
      providesTags: ['GET_SYNC_CERTIFICATE'],
    }),

    /**
     * My Crew: Post Sync Certificate
     */
    postSyncCertificate: builder.mutation<void, IRequestPostSyncCertificate>({
      query: data => ({
        url: `/v1/company/company-assignment/${data.crewCompanyAssignmentId}/certificate/sync`,
        method: 'POST',
        body: data.payload,
      }),
      invalidatesTags: ['GET_SYNC_CERTIFICATE'],
    }),

    /**
     * My Crew: Get Sync Certificate Status
     */
    getSyncCertificateStatus: builder.query<
      TReturnGetSyncCertificateStatus,
      IRequestGetSyncCertificateStatus
    >({
      query: ({ crewCompanyAssignmentId }) =>
        `/v1/company/company-assignment/${crewCompanyAssignmentId}/certificate/sync/status`,
      providesTags: ['GET_SYNC_CERTIFICATE_STATUS'],
    }),
  }),
});

export const {
  useGetSyncCertificateQuery,
  usePostSyncCertificateMutation,
  useLazyGetSyncCertificateStatusQuery,
} = certificateApi;
