import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { IDataDropdown } from '@/interfaces/dropdown';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  TRequestGetVesselTypeSpesific,
  TResponseGetCertificate,
  TResponseGetDocument,
  TResponseGetVesselType,
  TReturnGetVesselTypeSpesific,
} from './type';

//TODO: move all api that interact with master data to this file
export const masterDataApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'masterDataApi',
  endpoints: builder => ({
    getCertificate: builder.query<IDataDropdown[], void>({
      query: () => '/v1/master-data/certificate',
      transformResponse: (response: TResponseGetCertificate) => {
        return response.map(item => ({
          value: item.id,
          label: item.label,
        }));
      },
    }),

    getDocument: builder.query<IDataDropdown[], void>({
      query: () => ({
        url: '/v1/master-data/document',
        method: 'GET',
      }),
      transformResponse: (response: TResponseGetDocument) => {
        return response.map(item => ({
          value: item.id,
          label: item.label,
        }));
      },
    }),

    // vessel type mapped by name
    getVesselTypeByName: builder.query<IDataDropdown[], void>({
      query: () => '/v1/master-data/vessel-type',
      transformResponse: (response: TResponseGetVesselType) => {
        return response.flatMap(item =>
          item.subItems.map(subItem => ({
            value: subItem.value,
            label: `${item.label} - ${subItem.label}`,
          }))
        );
      },
    }),
    // vessel type mapped by id
    getVesselTypeById: builder.query<IDataDropdown[], void>({
      query: () => '/v1/master-data/vessel-type',
      transformResponse: (response: TResponseGetVesselType) => {
        return response.flatMap(item =>
          item.subItems.map(subItem => ({
            value: subItem.id,
            label: `${item.label} - ${subItem.label}`,
          }))
        );
      },
    }),
    // vessel type spesific
    getVesselTypeSpesific: builder.query<
      TReturnGetVesselTypeSpesific,
      TRequestGetVesselTypeSpesific
    >({
      query: ({ vesselTypeSpesific }) => `/v1/master-data/vessel-type/${vesselTypeSpesific}`,
    }),
  }),
});

export const {
  useGetCertificateQuery,
  useGetDocumentQuery,
  useGetVesselTypeByNameQuery,
  useGetVesselTypeByIdQuery,
  useLazyGetVesselTypeByIdQuery,
  useLazyGetVesselTypeSpesificQuery,
} = masterDataApi;
