import LayoutErrorNoInternet from '@/components/organisms/layout/error/NoInternet';

interface IProps {
  children: React.ReactNode;
}

export default function LayoutErrorTemplate({ children }: IProps) {
  return (
    <div>
      {/* error layouts here */}
      <LayoutErrorNoInternet>{children}</LayoutErrorNoInternet>
    </div>
  );
}
