import { jobsApi } from '@/api/jobs';
import { rankApi } from '@/api/rank';
import { crewApi } from '@/api/crew';
import { roleApi } from '@/api/role';
import { rateApi } from '@/api/rate';
import { fileApi } from '@/api/file';
import { adminApi } from '@/api/admin';
import { colorApi } from '@/api/color';
import { vesselApi } from '@/api/vessel';
import { companyApi } from '@/api/company';
import { documentApi } from '@/api/document';
import { authUserApi } from '@/api/auth/user';
import { dashboardApi } from '@/api/dashboard';
import { authLoginApi } from '@/api/auth/login';
import { masterDataApi } from '@/api/masterData';
import { configureStore } from '@reduxjs/toolkit';
import { certificateApi } from '@/api/certificate';
import { coachmarkApi } from '@/api/coachmark';
import { notificationApi } from '@/api/notification';
import { conversationApi } from '@/api/conversation';
import { authRegisterApi } from '@/api/auth/register';
import { jobsApplicationApi } from '@/api/jobs/application';
import { downloadDocumentsApi } from '@/api/download/documents';
import { downloadCertificatesApi } from '@/api/download/certificates';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';

import rootReducer from './reducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authRegisterApi.middleware,
      authLoginApi.middleware,
      authUserApi.middleware,
      vesselApi.middleware,
      jobsApi.middleware,
      jobsApplicationApi.middleware,
      rankApi.middleware,
      masterDataApi.middleware,
      crewApi.middleware,
      adminApi.middleware,
      roleApi.middleware,
      dashboardApi.middleware,
      companyApi.middleware,
      rateApi.middleware,
      notificationApi.middleware,
      downloadCertificatesApi.middleware,
      downloadDocumentsApi.middleware,
      fileApi.middleware,
      conversationApi.middleware,
      documentApi.middleware,
      certificateApi.middleware,
      coachmarkApi.middleware,
      colorApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
