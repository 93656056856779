import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IRequestAddRole,
  IRequestEditRole,
  roleSection,
  TResponseRoles,
  TReturnRoleAccessList,
} from './type';
import type { IRoleListItem } from '@/interfaces/role';

export const roleApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'roleApi',
  tagTypes: ['roles'],
  endpoints: builder => ({
    getRoles: builder.query<IRoleListItem[], void>({
      query: () => '/v1/company/role',
      transformResponse: (response: TResponseRoles) => {
        return response.map(item => {
          const formattedItem: IRoleListItem = {
            id: item.id,
            value: item.value,
            label: item.label,
            job: [],
            rate: [],
            setting: [],
            all: [],
            vessel: [],
            myCrew: [],
            crew: [],
          };

          item.accesses.forEach(access => {
            const formattedAccess = {
              id: access.id,
              value: access.value,
              label: access.label,
              tooltip: {
                title: access.label,
                description: access.description,
              },
            };
            formattedItem[roleSection[access.Section]]?.push(formattedAccess);
          });

          return formattedItem;
        });
      },
      providesTags: ['roles'],
    }),
    addRole: builder.mutation<void, IRequestAddRole>({
      query: payload => ({
        url: '/v1/company/role',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['roles'],
    }),
    editRole: builder.mutation<void, IRequestEditRole>({
      query: payload => ({
        url: '/v1/company/role',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['roles'],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/v1/company/role/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['roles'],
    }),

    getRoleAccess: builder.query<TReturnRoleAccessList, void>({
      query: () => ({
        url: `/v1/company/access/list`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRolesQuery,
  useAddRoleMutation,
  useEditRoleMutation,
  useDeleteRoleMutation,
  useGetRoleAccessQuery,
} = roleApi;
