import {
  IRegisterForm,
  IRegisterFormBasicInfo,
  IRegisterFormDocument,
  IRegisterFormOtherInfo,
} from '@/interfaces/register/form';
import { RootState } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getInitialState = (): IRegisterForm => ({
  basicInfo: {
    companyName: '',
    companyAddress: '',
    companyPhoneNumber: '',
    companyWebsite: '',
    companyCountry: 'ID',
  },
  document: {
    businessNumber: '', // Nomor Usaha
    taxIdentityNumber: '', // NPWP
    directorName: '', // Nama Direktur
    directorIdentityNumber: '', // KTP Direktur
    directorEmail: '', // Email Direktur
    directorPhoneNumber: '', // Nomor Telepon Direktur
    taxIdentityNumberFile: null, // NPWP
    deedOfEstablishmentFile: null, // Akta Pendirian
    directorIdentityNumberFile: null, // KTP Direktur
  },
  otherInfo: {
    picName: '', // Nama PIC
    picPhoneNumber: '', // Nomor Telepon PIC
    picJobTitle: '', // Jabatan PIC
    picEmail: '', // Email PIC
  },
});

export const registerFormSlice = createSlice({
  name: 'registerForm',
  initialState: getInitialState(),
  reducers: {
    saveFormBasicInfo: (state, action: PayloadAction<IRegisterFormBasicInfo>) => {
      state.basicInfo = action.payload;
    },
    saveFormDocument: (state, action: PayloadAction<IRegisterFormDocument>) => {
      state.document = action.payload;
    },
    saveFormOtherInfo: (state, action: PayloadAction<IRegisterFormOtherInfo>) => {
      state.otherInfo = action.payload;
    },
    resetForm: state => {
      state = getInitialState();
    },
  },
});

export const getRegisterFormBasicInfo = (state: RootState) => state.registerForm.basicInfo;
export const getRegisterFormDocument = (state: RootState) => state.registerForm.document;
export const getRegisterFormOtherInfo = (state: RootState) => state.registerForm.otherInfo;

export const { saveFormBasicInfo, saveFormDocument, saveFormOtherInfo, resetForm } =
  registerFormSlice.actions;

export default registerFormSlice.reducer;
