import { RootState } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IOnboardingCredential {
  token: string;
  email: string;
}

export interface IOnboardingUserData {
  position: string;
  email: string;
  role: {
    value: string;
    label: string;
  };
}

interface IInitialState {
  credential: IOnboardingCredential;
  password: string;
  user: IOnboardingUserData;
}

const initialState: IInitialState = {
  credential: {
    token: '',
    email: '',
  },
  password: '',
  user: {
    position: '',
    email: '',
    role: {
      value: '',
      label: '',
    },
  },
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    saveOnboardingCredential: (state, action: PayloadAction<IInitialState['credential']>) => {
      state.credential = action.payload;
    },
    saveOnboardingPasswordTemporarily: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    saveOnboardingUserData: (state, action: PayloadAction<IInitialState['user']>) => {
      state.user = action.payload;
    },
  },
});

export const {
  saveOnboardingPasswordTemporarily,
  saveOnboardingCredential,
  saveOnboardingUserData,
} = onboardingSlice.actions;

export const getOnboardingCredential = (state: RootState) => state.onboarding.credential;
export const getOnboardingUserData = (state: RootState) => state.onboarding.user;
export const getOnboardingTemporaryPassword = (state: RootState) => state.onboarding.password;

export default onboardingSlice.reducer;
