import mixpanel from 'mixpanel-browser';

export type TTrackEvent =
  | 'sidebar-menu_clicked'
  // vessel
  | 'vessel-detail_change-vessel'
  | 'vessel-detail_change-to-tab-planning'
  | 'vessel-detail_change-to-tab-log'
  | 'vessel-detail-planning_change-duration-mode-month'
  | 'vessel-detail-planning_change-duration-mode-week'
  | 'vessel-detail-planning_change-next-date'
  | 'vessel-detail-planning_change-previous-date'
  | 'vessel-detail-log_create-log'
  // jobs
  | 'job-list_click-create-job'
  | 'job-list_click-job-detail'
  | 'job-list_toggle-tab'
  // job detail
  // TODO: implement job detail action
  | 'job-detail_click-prompt-close-job'
  | 'job-detail_click-prompt-duplicate-job'
  | 'job-detail_close-job'
  | 'job-detail_navigate-duplicate-job'
  | 'job-detail_click-edit-job'
  | 'job-detail_click-share-job'
  | 'job-detail_click-applicant-detail'
  //
  | 'job-applicant-detail_click-send-message'
  | 'job-applicant-detail_click-open-shortlist'
  | 'job-applicant-detail_click-shortlist-reject'
  | 'job-applicant-detail_click-job-offer'
  //
  | 'crew-detail_click-open-log'
  // general action
  | 'action_click-init-conversation'
  | 'action_click-open-modal-job-offer'
  | 'action_click-open-modal-invite-interview'
  | 'action_click-open-modal-final-offer'
  | 'action_click-open-modal-reject-candidate'
  | 'action_submit-shortlist'
  | 'action_submit-job-offer'
  | 'action_submit-invite-interview'
  | 'action_submit-final-offer'
  | 'action_submit-reject-candidate'
  | 'action_click-open-modal-view-document-ktp'
  | 'action_click-open-modal-view-document-passport'
  | 'action_click-open-modal-view-document-boc'
  | 'action_click-open-modal-view-document-photograph'
  | 'action_click-open-modal-view-document-cv'
  | 'action_click-open-modal-view-document-lastSea'
  | 'action_click-open-modal-view-document-seabook'
  | 'action_click-open-modal-view-document-familyCard'
  | 'action_click-open-modal-view-document-sailingLicense'
  | 'action_click-open-modal-view-document-mcu'
  | 'action_click-open-modal-view-certificate'
  // TODO: add more job detail
  // auth
  | 'auth-user_authenticated';

/**
 *
 * @param event name of event with convention `<screen/feature-name>_<action-name>` ex: register_navigate-to-login
 * @param data
 */
export const track = (event: TTrackEvent, data?: any) => {
  try {
    mixpanel.track(event, data);
  } catch (error) {
    console.error(error);
  }
};

export const setMixpanelUserData = (attributeName: string, data: any) => {
  try {
    mixpanel.people.set(attributeName, data);
  } catch (error) {
    console.error(error);
  }
};
