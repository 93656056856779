import { TUserGender } from '@/interfaces/user';
import { t } from 'i18next';

/**
 * transform from backend response to label
 * @param isMale boolean, response from backend
 * @returns
 */
export const getGenderValueFromResponse = (isMale: boolean): TUserGender => {
  return isMale ? 'male' : 'female';
};
export const getGenderLabelFromResponse = (isMale: boolean) => {
  return isMale ? t('common:label:male') : t('common:label:female');
};

export const getGenderLabel = (value: TUserGender | null | undefined) => {
  if (value === 'male') {
    return t('common:label:male');
  }
  if (value === 'female') {
    return t('common:label:female');
  }
  return '';
};

export const getIsMaleValue = (value: TUserGender | null) => {
  return value === 'male';
};
