import { useDispatch } from 'react-redux';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { coachmarkApi } from '@/api/coachmark';
import { STEPS_DATA } from '@/constants/coachmark';

import CoachmarkItem from '@/components/molecules/Coachmark';
import useFetchCoachmark from '@/hooks/api/coachmark/useFetchCoachmark';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

export interface IProvidersCoachmarkProps {
  children: ReactNode;
}

export default function ProvidersCoachmark(props: IProvidersCoachmarkProps) {
  const dispatch = useDispatch();

  const [run, setRun] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname);

  const { dataCoachmark, isLoadingCoachmark, handlePostCoachmark } = useFetchCoachmark();

  const getStepsData = useMemo(
    () => STEPS_DATA.find(item => item.location === pathname),
    [pathname]
  );

  const stepsDataItem = getStepsData?.steps;
  const stepsDataEnum = getStepsData?.enum;

  const handleJoyrideCallback = (data: CallBackProps) => {
    if (!getStepsData || !stepsDataEnum) return;

    const { status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      handlePostCoachmark({
        features: [stepsDataEnum],
        status: 'DONE',
      });
    }
  };

  //check if pathname is changed
  useEffect(() => {
    const checkPathname = () => {
      const currentPathname = window.location.pathname;
      if (currentPathname !== pathname) {
        setPathname(currentPathname);
      }
    };

    const intervalId = setInterval(checkPathname, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [pathname]);

  useEffect(() => {
    dispatch(coachmarkApi.util.invalidateTags(['COACHMARK']));
  }, [pathname]);

  useEffect(() => {
    if (stepsDataItem && stepsDataEnum && stepsDataItem.length > 0) {
      const checkElements = () => {
        const allElementsPresent = stepsDataItem.every(step => {
          const target = step.target;
          if (typeof target === 'string') {
            return document.querySelector(target);
          } else {
            return target instanceof HTMLElement; // Ensure the target is an HTMLElement
          }
        });

        if (allElementsPresent && !dataCoachmark?.includes(stepsDataEnum) && !isLoadingCoachmark) {
          setRun(true);
        } else {
          setTimeout(checkElements, 100);
        }
      };

      checkElements();
    }
  }, [pathname, stepsDataItem, dataCoachmark, isLoadingCoachmark, stepsDataEnum]);

  return (
    <>
      <Joyride
        run={run}
        tooltipComponent={CoachmarkItem}
        steps={stepsDataItem}
        callback={handleJoyrideCallback}
        continuous
        disableOverlayClose
      />

      {props.children}
    </>
  );
}
