import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { IDataDropdown } from '@/interfaces/dropdown';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IRequestGetCompanyVesselRanks,
  IReturnPureRank,
  TResponseGetCompanyVesselRanks,
  TResponseGetRanks,
  IRequestGetRank,
} from './type';

export const rankApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'rankApi',
  tagTypes: ['ranks', 'COMPANY_VESSEL_RANKS'],
  endpoints: builder => ({
    // return id as value
    getRanksWithId: builder.query<IDataDropdown[], IRequestGetRank | undefined | void>({
      query: payload => ({
        url: '/v1/master-data/rank',
        params: {
          action: payload?.action,
        },
      }),

      providesTags: ['ranks'],
      transformResponse: (response: TResponseGetRanks) => {
        // flatten the response, and return the data in the format that the dropdown component expects
        return response.reduce((acc, curr) => {
          const list = curr.list.map(el => ({
            value: el.id,
            label: `${curr.label} - ${el.label}`,
          }));
          return [...acc, ...list];
        }, [] as IDataDropdown[]);
      },
    }),
    getRanks: builder.query<IDataDropdown[], void>({
      query: () => '/v1/master-data/rank',
      providesTags: ['ranks'],
      transformResponse: (response: TResponseGetRanks) => {
        // flatten the response, and return the data in the format that the dropdown component expects
        return response.reduce((acc, curr) => {
          const list = curr.list.map(el => ({
            value: el.value,
            label: `${curr.label} - ${el.label}`,
          }));
          return [...acc, ...list];
        }, [] as IDataDropdown[]);
      },
    }),
    getPureRanks: builder.query<IReturnPureRank[], void>({
      query: () => '/v1/master-data/rank',
      providesTags: ['ranks'],
    }),

    getCompanyVesselRanks: builder.query<IDataDropdown[], IRequestGetCompanyVesselRanks>({
      query: ({ vesselId }) => `/v1/company/company-vessel/${vesselId}/rank`,
      providesTags: ['COMPANY_VESSEL_RANKS'],
      transformResponse: (response: TResponseGetCompanyVesselRanks) => {
        return response.map(el => ({
          value: el.id,
          label: el.label,
        }));
      },
    }),
  }),
});

export const {
  useGetRanksWithIdQuery,
  useLazyGetRanksWithIdQuery,
  useGetRanksQuery,
  useLazyGetRanksQuery,
  useGetPureRanksQuery,
  useLazyGetCompanyVesselRanksQuery,
} = rankApi;
