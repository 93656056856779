import { twMerge } from 'tailwind-merge';

interface IProps {
  children: React.ReactNode;
  extraRounded?: boolean;
  extraShadow?: boolean;
  noPadding?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function Card({
  children,
  extraRounded,
  extraShadow,
  noPadding,
  className,
  onClick,
}: IProps) {
  const roundedClass = extraRounded ? 'rounded-2xl' : 'rounded-lg';
  const paddingClass = noPadding ? 'p-0' : 'p-9';
  const shadowClass = extraShadow ? 'shadow-lg' : 'shadow-sm';

  return (
    <div
      onClick={onClick}
      className={twMerge(
        'border-1 border-gray-200 border bg-white',
        paddingClass,
        roundedClass,
        shadowClass,
        className
      )}
    >
      {children}
    </div>
  );
}
