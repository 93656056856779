import { mantisPublicApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';

export const authRegisterApi = createApi({
  ...mantisPublicApiConfig,
  reducerPath: 'authRegisterApi',
  endpoints: builder => ({
    registerCompany: builder.mutation<null, FormData>({
      query: body => ({
        url: '/v1/register/company',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useRegisterCompanyMutation } = authRegisterApi;
