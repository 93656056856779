import { lazy, Suspense } from 'react';

import ProgressBar from 'react-topbar-progress-indicator';
import { Navigate, Route, Routes } from 'react-router-dom';

import useCheckAuth from '@/hooks/auth/useCheckAuth';

import SuspenseRoute from './suspenseRoute';
const ProtectedRoute = lazy(() => import('./protectedRoute'));

// Layout
const RootApp = lazy(() => import('@/pages'));
const Dashboard = lazy(() => import('@/pages/Dashboard'));
const LayoutOnboarding = lazy(() => import('@/pages/layout/Onboarding'));
const LayoutMain = lazy(() => import('@/pages/layout/Main'));
const LayoutDetail = lazy(() => import('@/pages/layout/Detail'));
const LayoutSetting = lazy(() => import('@/pages/layout/Setting'));

// Auth
const Login = lazy(() => import('@/pages/Login'));
const Register = lazy(() => import('@/pages/Register'));
const Logout = lazy(() => import('@/pages/Logout'));
const ForgotPassword = lazy(() => import('@/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('@/pages/ResetPassword'));
const ResetPasswordSuccess = lazy(() => import('@/pages/ResetPassword/Success'));
const RegisterBasicInfo = lazy(() => import('@/pages/Register/BasicInfo'));
const RegisterDocument = lazy(() => import('@/pages/Register/Document'));
const RegisterOtherInfo = lazy(() => import('@/pages/Register/OtherInfo'));
const RegisterSuccess = lazy(() => import('@/pages/Register/Success'));
//
const Notification = lazy(() => import('@/pages/Notification'));
const Messages = lazy(() => import('@/pages/Messages'));
// Onboarding
const OnboardingAccountSetup = lazy(() => import('@/pages/onboarding/Account/Setup'));
const OnboardingAccountUsers = lazy(() => import('@/pages/onboarding/Account/Users'));
const OnboardingAccountSuccess = lazy(() => import('@/pages/onboarding/Account/Success'));
// Crew
const ContactedCrew = lazy(() => import('@/pages/Crew/List/ContactedCrew'));
const CrewListDetail = lazy(() => import('@/pages/Crew/List/Detail'));
const CrewDetailOffer = lazy(() => import('@/pages/Crew/Detail/Offer'));
const CrewDetailOfferView = lazy(() => import('@/pages/Crew/Detail/Offer/View'));
const CrewList = lazy(() => import('@/pages/Crew/List'));
const CrewDetail = lazy(() => import('@/pages/Crew/Detail'));
// My Crew
const MyCrew = lazy(() => import('@/pages/MyCrew'));
const MyCrewDetail = lazy(() => import('@/pages/MyCrew/Detail'));
const MyCrewDetailModalSendInvitation = lazy(() => import('@/pages/MyCrew/modal/SendInvitation'));
// My Crew Modal
const MyCrewModalManageContract = lazy(() => import('@/pages/MyCrew/modal/ManageContract'));
const MyCrewModalTerminateContract = lazy(() => import('@/pages/MyCrew/modal/TerminateContract'));
// Jobs
const JobsList = lazy(() => import('@/pages/Jobs'));
const JobsCreate = lazy(() => import('@/pages/Jobs/Create'));
const JobsDetail = lazy(() => import('@/pages/Jobs/Detail'));
const JobsEdit = lazy(() => import('@/pages/Jobs/Edit'));
// Settings
const Setting = lazy(() => import('@/pages/Setting'));
const SettingManageRole = lazy(() => import('@/pages/Setting/manage/Role'));
const SettingAccount = lazy(() => import('@/pages/Setting/Account'));
const SettingManageAdmin = lazy(() => import('@/pages/Setting/manage/Admin'));
const SettingAccountCompanyGeneral = lazy(() => import('@/pages/Setting/Account/company/General'));
const SettingAccountCompanyGeneralEdit = lazy(
  () => import('@/pages/Setting/Account/company/General/Edit')
);
const SettingAccountCompanyDetail = lazy(() => import('@/pages/Setting/Account/company/Detail'));
const SettingAccountCompanyDetailEdit = lazy(
  () => import('@/pages/Setting/Account/company/Detail/Edit')
);
const SettingAccountPersonalInfo = lazy(() => import('@/pages/Setting/Account/personal/Info'));
const SettingAccountPersonalInfoEdit = lazy(
  () => import('@/pages/Setting/Account/personal/Info/Edit')
);
const SettingAccountPersonalChangePassword = lazy(
  () => import('@/pages/Setting/Account/personal/ChangePassword')
);
const SettingManageAdminEdit = lazy(() => import('@/pages/Setting/manage/Admin/Edit'));
const SettingManageAdminCreate = lazy(() => import('@/pages/Setting/manage/Admin/Create'));
const SettingManageRoleEdit = lazy(() => import('@/pages/Setting/manage/Role/Edit'));
const SettingManageRoleCreate = lazy(() => import('@/pages/Setting/manage/Role/Create'));
const SettingColor = lazy(() => import('@/pages/Setting/Color'));
const SettingColorModalDelete = lazy(() => import('@/pages/Setting/Color/modal/Delete'));
const SettingColorModalForm = lazy(() => import('@/pages/Setting/Color/modal/Form'));
// Vessel
const VesselList = lazy(() => import('@/pages/vessel/List'));
const VesselCreate = lazy(() => import('@/pages/vessel/Create'));
const VesselEdit = lazy(() => import('@/pages/vessel/Edit'));
const VesselDetail = lazy(() => import('@/pages/vessel/Detail'));
const VesselDetailModalAddCrew = lazy(() => import('@/pages/vessel/Detail/modal/AddCrew'));
const VesselDetailModalAddCrewConfirm = lazy(
  () => import('@/pages/vessel/Detail/modal/AddCrew/Confirm')
);

ProgressBar.config({
  barColors: {
    '0': '#000000',
    '1.0': '#000000',
  },
  shadowBlur: 5,
});

const RouteSuspense = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<ProgressBar />}>{children}</Suspense>
);

export default function Router() {
  useCheckAuth();

  return (
    <SuspenseRoute window={window}>
      <RouteSuspense>
        <Routes>
          <Route
            path="/login"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="unauthenticated">
                  <Login />
                </ProtectedRoute>
              </RouteSuspense>
            }
          />

          <Route
            path="/logout"
            element={
              <RouteSuspense>
                <Logout />
              </RouteSuspense>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="unauthenticated">
                  <ForgotPassword />
                </ProtectedRoute>
              </RouteSuspense>
            }
          />

          <Route
            path="/reset-password"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="unauthenticated">
                  <ResetPassword />
                </ProtectedRoute>
              </RouteSuspense>
            }
          />

          <Route
            path="/reset-password/success"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="unauthenticated">
                  <ResetPasswordSuccess />
                </ProtectedRoute>
              </RouteSuspense>
            }
          />

          <Route
            path="/register"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="unauthenticated">
                  <Register />
                </ProtectedRoute>
              </RouteSuspense>
            }
          >
            <Route
              path="basic-info"
              element={
                <RouteSuspense>
                  <RegisterBasicInfo />
                </RouteSuspense>
              }
            />
            <Route
              path="document"
              element={
                <RouteSuspense>
                  <RegisterDocument />
                </RouteSuspense>
              }
            />
            <Route
              path="other-info"
              element={
                <RouteSuspense>
                  <RegisterOtherInfo />
                </RouteSuspense>
              }
            />
          </Route>

          <Route
            path="/register/success"
            element={
              <RouteSuspense>
                <RegisterSuccess />
              </RouteSuspense>
            }
          />

          <Route
            path="/onboarding/account"
            element={
              <RouteSuspense>
                <LayoutOnboarding />
              </RouteSuspense>
            }
          >
            <Route
              path="setup"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="unauthenticated">
                    <OnboardingAccountSetup />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="users"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <OnboardingAccountUsers />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
          </Route>

          <Route
            path="/onboarding/account/success"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="authenticated">
                  <OnboardingAccountSuccess />
                </ProtectedRoute>
              </RouteSuspense>
            }
          />
          {/* LayoutMain: With Sidebar Menu */}
          <Route
            path="/"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="authenticated">
                  <LayoutMain />
                </ProtectedRoute>
              </RouteSuspense>
            }
          >
            <Route
              path="/"
              element={
                <RouteSuspense>
                  <RootApp />
                </RouteSuspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RouteSuspense>
                  <Dashboard />
                </RouteSuspense>
              }
            />
            <Route
              path="/jobs"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_JOB_MENU']}
                  >
                    <JobsList />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="/crew/list/:rankCategory?"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_CREW_MENU']}
                  >
                    <CrewList />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/crew/contacted-crew"
              element={
                <RouteSuspense>
                  <ContactedCrew />
                </RouteSuspense>
              }
            />

            <Route
              path="/my-crew"
              element={
                <RouteSuspense>
                  <MyCrew />
                </RouteSuspense>
              }
            >
              <Route
                path="/my-crew/manage-contract"
                element={
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['CREATE_NEW_CONTRACT', 'EXTEND_CONTRACT_ROTATIONAL_PLAN']}
                  >
                    <RouteSuspense>
                      <MyCrewModalManageContract />
                    </RouteSuspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-crew/terminate-contract"
                element={
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['TERMINATE_CONTRACT']}
                  >
                    <RouteSuspense>
                      <MyCrewModalTerminateContract />
                    </RouteSuspense>
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="/crew/contacted-crew/:crewId"
              element={
                <RouteSuspense>
                  <CrewDetail />
                </RouteSuspense>
              }
            />
            <Route
              path="/crew/list/:rankCategory/:rankValue"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_CREW_LIST']}
                  >
                    <CrewListDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/crew/list/:rankCategory/:rankValue/:crewId"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <CrewDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/crew/:crewId/offer"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <CrewDetailOffer />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/crew/:crewId/offer/view"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <CrewDetailOfferView />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="/vessel/list"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_VESSEL_MENU']}
                  >
                    <VesselList />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/vessel/create"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <VesselCreate />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/vessel/:vesselId/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <VesselEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
          </Route>

          {/* Layout Detail: Without Sidebar */}
          <Route
            path="/"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="authenticated">
                  <LayoutDetail />
                </ProtectedRoute>
              </RouteSuspense>
            }
          >
            <Route
              path="/vessel/:vesselId"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <VesselDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            >
              <Route
                path="/vessel/:vesselId/add-crew"
                element={
                  <RouteSuspense>
                    <ProtectedRoute onlyFor="authenticated">
                      <VesselDetailModalAddCrew />
                    </ProtectedRoute>
                  </RouteSuspense>
                }
              >
                <Route
                  path="/vessel/:vesselId/add-crew/confirm"
                  element={
                    <RouteSuspense>
                      <ProtectedRoute onlyFor="authenticated">
                        <VesselDetailModalAddCrewConfirm />
                      </ProtectedRoute>
                    </RouteSuspense>
                  }
                />
              </Route>
            </Route>

            <Route
              path="/messages/?"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <Messages />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/jobs/create"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['POST_JOB']}
                  >
                    <JobsCreate />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/jobs/:id/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['EDIT_JOB']}
                  >
                    <JobsEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/jobs/:id"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_JOB_MENU']}
                  >
                    <JobsDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="/crew/:crewId"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <CrewDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="/my-crew/:crewCompanyAssignmentId"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <MyCrewDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            >
              <Route
                path="/my-crew/:crewCompanyAssignmentId/send-invitation"
                element={
                  <RouteSuspense>
                    <MyCrewDetailModalSendInvitation />
                  </RouteSuspense>
                }
              />
              <Route
                path="/my-crew/:crewCompanyAssignmentId/manage-contract"
                element={
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['CREATE_NEW_CONTRACT', 'EXTEND_CONTRACT_MY_CREW']}
                  >
                    <RouteSuspense>
                      <MyCrewModalManageContract />
                    </RouteSuspense>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-crew/:crewCompanyAssignmentId/terminate-contract"
                element={
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['TERMINATE_CONTRACT']}
                  >
                    <RouteSuspense>
                      <MyCrewModalTerminateContract />
                    </RouteSuspense>
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="/notification"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <Notification />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Route>

          {/* Settings Layout*/}
          <Route
            path="/setting"
            element={
              <RouteSuspense>
                <ProtectedRoute onlyFor="authenticated">
                  <LayoutSetting />
                </ProtectedRoute>
              </RouteSuspense>
            }
          >
            <Route
              path=""
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <Setting />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccount />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account/company/general"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccountCompanyGeneral />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account/company/general/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_EDIT_COMPANY_INFO']}
                  >
                    <SettingAccountCompanyGeneralEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account/company/detail"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccountCompanyDetail />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account/company/detail/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_EDIT_COMPANY_DETAIL']}
                  >
                    <SettingAccountCompanyDetailEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account/personal/info"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccountPersonalInfo />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="account/personal/info/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccountPersonalInfoEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="account/personal/change-password"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccountPersonalChangePassword />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="account/personal/Info/Edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute onlyFor="authenticated">
                    <SettingAccountPersonalInfoEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="manage-role"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_MANAGEMENT_ADMIN']}
                  >
                    <SettingManageRole />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="manage-role/create"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['CREATE_ROLE']}
                  >
                    <SettingManageRoleCreate />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="manage-role/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['EDIT_ROLE']}
                  >
                    <SettingManageRoleEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />
            <Route
              path="admin"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_MANAGEMENT_ADMIN']}
                  >
                    <SettingManageAdmin />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="admin/edit"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['EDIT_ADMIN']}
                  >
                    <SettingManageAdminEdit />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="admin/create"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['ADD_ADMIN']}
                  >
                    <SettingManageAdminCreate />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            />

            <Route
              path="color-tag"
              element={
                <RouteSuspense>
                  <ProtectedRoute
                    onlyFor="authenticated"
                    allowAccesses={['VIEW_COMPANY_COLORS']}
                  >
                    <SettingColor />
                  </ProtectedRoute>
                </RouteSuspense>
              }
            >
              <Route
                path="create"
                element={
                  <RouteSuspense>
                    <ProtectedRoute
                      onlyFor="authenticated"
                      allowAccesses={['VIEW_COMPANY_COLORS']}
                    >
                      <SettingColorModalForm />
                    </ProtectedRoute>
                  </RouteSuspense>
                }
              />
              <Route
                path=":colorId/edit"
                element={
                  <RouteSuspense>
                    <ProtectedRoute
                      onlyFor="authenticated"
                      allowAccesses={['VIEW_COMPANY_COLORS']}
                    >
                      <SettingColorModalForm />
                    </ProtectedRoute>
                  </RouteSuspense>
                }
              />
              <Route
                path=":colorId/delete"
                element={
                  <RouteSuspense>
                    <ProtectedRoute
                      onlyFor="authenticated"
                      allowAccesses={['VIEW_COMPANY_COLORS']}
                    >
                      <SettingColorModalDelete />
                    </ProtectedRoute>
                  </RouteSuspense>
                }
              />
            </Route>
          </Route>
        </Routes>
      </RouteSuspense>
    </SuspenseRoute>
  );
}
